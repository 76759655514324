<template>
  <v-container>
    <v-row class="content">
      <v-col>
        <!-- Banner Carousel at the Top -->
        <v-carousel height="400" width="400" v-if="this.$vuetify.display.smAndUp" hide-delimiters
          :show-arrows="false" hide-delimiter-background>
          <v-carousel-item v-for="(banner, index) in banners" :key="index" :src="banner"
            :cover="false"></v-carousel-item>
        </v-carousel>
        <v-carousel height="200" width="200" v-if="!this.$vuetify.display.smAndUp" hide-delimiters :show-arrows="false"
          hide-delimiter-background>
          <v-carousel-item v-for="(banner, index) in mobileBanners" :key="index" :src="banner"
            :cover="false"></v-carousel-item>
        </v-carousel>
        <v-card class="mx-auto card-margin">
          <v-row no-gutters>
            <!-- Image on the Left Side -->
            <v-col cols="12" md="4">
              <v-img src="@/assets/dr-seema-rao.jpg" alt="Dr. Seema Rao" height="100%"></v-img>
            </v-col>

            <!-- Content on the Right Side -->
            <v-col cols="12" md="8">
              <v-card-title class="font-weight-black">Producer & Host</v-card-title>
              <v-card-text>
                <p>DR. SEEMA RAO is India's first & only woman commando trainer, spending 25 years training 20,000
                  commandos from special forces & 12 state Police Anti-Terror squads without compensation as selfless
                  service to the Nation. Her books on Commando training were accepted in FBI, INTERPOL & Buckingham
                  libraries.</p>

                <p>Seema studied Immunology from Harvard, Human rights from Stanford & Leadership from Westminster
                  Business School. A 7-time TEDX speaker, Mrs. India World runner-up, Seema is an inspiration to
                  millions of women worldwide as an epitome of empowerment to shatter the gender barrier and achieve
                  maximum potential.</p>

                <p>Ranked by Forbes as No. 6 in Woman Power, voted 'Woman We Love' by Femina, selected as 1 of 14 most
                  influential women in the world, Indian Express 'Dynamic Woman of the Year', selected Reebok 'Fit to
                  Fight Woman of the Year', Harper Bazaar's 'Most Inspiring Woman', 1 of the Top 100 power women listed
                  by India Today, World Peace awardee & Winner of highest National Narishakti Award from the President
                  of India!</p>

                <p>Dr. Seema Rao is aptly called India's Wonder Woman!</p>
                <p>Click <a href="https://drseemarao.com" target="_blank" rel="noopener noreferrer">here</a> for more
                  details.</p>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mx-auto card-margin">
          <v-row no-gutters>
            <!-- Image on the Left Side -->
            <v-col cols="12" md="4">
              <v-img src="@/assets/dr-komal-rao.jpg" alt="Dr. Komal Rao" height="100%" cover></v-img>
            </v-col>

            <!-- Content on the Right Side -->
            <v-col cols="12" md="8">
              <v-card-title class="font-weight-black">Co-Host</v-card-title>
              <v-card-text>
                <p>
                  Dr. Komal Rao is a Doctor by Education. She is a Harvard Business School Alumnus and also a PhD in
                  naturopathy. She has certified in Exercise Physiology from Stanford University and Cognitive
                  Behavioral
                  Psychotherapy from Beck Institute, and in Psychology from the American Psychological Association.
                </p>
                <p>She is a Sports Model, TEDX speaker & Pro Mixed Martial Arts Athlete. Dr. Komal Rao is the
                  Administrative
                  Director of an NGO UCCA.
                </p>
                <p>Dr. Komal Rao is a published Author on 'Art of the Dragon for Women's Safety,' a book on women
                  empowerment
                  featuring Jeet Kune Do.
                  Dr. Komal Rao received the Woman Super Achiever award from the 6th World Woman Leadership Congress in
                  2019.

                  Click <a href="https://aijh.in/drkomalrao" target="_blank" rel="noopener noreferrer">here</a> for more
                  details</p>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mx-auto card-margin">
          <v-row no-gutters>
            <!-- Image on the Left Side -->
            <v-col cols="12" md="4">
              <v-img src="@/assets/major-deepak-rao.jpeg" alt="Major Deepak Rao" height="100%" cover></v-img>
            </v-col>

            <!-- Content on the Right Side -->
            <v-col cols="12" md="8">
              <v-card-title class="font-weight-black">Director</v-card-title>
              <v-card-text>
                <p>Hon. Major Dr. Deepak Rao is Indian forces pioneer of modern Close Quarter Battle trg, 1 of only 6
                  Indians
                  to get coveted President's Rank honour since independence with MS Dhoni, Sachin Tendulkar, Kapil Dev,
                  Mohanlal & Abhinav Bindra. He is a commando trainer who has helped special forces for 25 years in
                  modern
                  methodology without compensation. He is the recipient of the World Peace Award in 2008 & US
                  president's
                  volunteer service award. He is a motivational speaker, a sports medicine physician, and specialist in
                  mind
                  studies with expertise in Zen, Psychotherapy, and CBT. Besides medical qualifications, he has
                  certifications
                  from Wharton, pharmacology from Harvard, Disaster medicine from Stanford, Sports medicine from Mayo,
                  and
                  CBT
                  psychotherapy from esteemed Beck Institute USA.
                </p>
                <p>His favorite maxim is "Ask not what your country can do for you, Ask what you can do for your
                  country"
                </p>
                <p>Click <a href="https://majordeepakrao.com" target="_blank" rel="noopener noreferrer">here</a> for
                  more
                  details</p>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TeamPage',
  data() {
    return {
      banners: [
        // require('@/assets/banner-3.png'),
        require('@/assets/banner-1.jpeg'),
        // require('@/assets/banner-2.jpg'),
      ],
      mobileBanners: [
        // require('@/assets/banner-mobile-3.png'),
        require('@/assets/banner-mobile-1.jpeg'),
        // require('@/assets/banner-mobile-2.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import TeamPage from '@/components/TeamPage.vue';
import ContactUs from '@/components/ContactUs.vue';

const routes = [
    { path: '/', component: HomePage, name: 'HomePage' },
    { path: '/team', component: TeamPage, name: 'TeamPage' },
    { path: '/contact-us', component: ContactUs, name: 'ContactUs' },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
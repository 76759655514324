<template>
  <v-app>
    <v-container fluid>
      <!-- Banner Carousel at the Top -->
      <v-carousel height="400" width="400" v-if="this.$vuetify.display.smAndUp" hide-delimiters :show-arrows="false"
        hide-delimiter-background>
        <v-carousel-item v-for="(banner, index) in banners" :key="index" :src="banner" :cover="false"></v-carousel-item>
      </v-carousel>
      <v-carousel height="200" width="200" v-if="!this.$vuetify.display.smAndUp" hide-delimiters :show-arrows="false" hide-delimiter-background>
        <v-carousel-item v-for="(banner, index) in mobileBanners" :key="index" :src="banner"
          :cover="false"></v-carousel-item>
      </v-carousel>

      <v-row>
        <v-col>
          <v-card class="mx-auto card-margin">
            <template v-slot:title>
              <span class="font-weight-black">Stree Bal, Desh Ka Bal</span>
            </template>

            <v-card-text class="bg-surface-light pt-4">
              Stree bal is an initiative by Dr Seema Rao, India's first woman commando trainer, to highlight the
              endeavors, struggles & extraordinary challenges in the life of the common woman. India's wonder woman
              along with Dr. Komal Rao brings to you stories of these unsung sheroes who form the backbone of our
              society.<br>
              This podcast was born out of a passion for empowering the ordinary everyday woman and sharing their
              stories. Each episode is
              designed
              to inspire and connect listeners with a variety of guests who share their journeys, insights, and
              knowledge.
              <p>The vision of the producer is to create a platform that showcases women's voices and stories from
                various
                backgrounds.</p>
              <p>They aim to foster community engagement and raise awareness about women's issues through insightful
                discussions.</p>
              <p>
                To contact us, please visit the
                <router-link :to="{ name: 'ContactUs' }">Contact Us</router-link> page.
              </p>
            </v-card-text>
          </v-card>

          <v-carousel height="576" width="576" v-if="!this.$vuetify.display.smAndDown" hide-delimiters
            :show-arrows="false" hide-delimiter-background>
            <v-carousel-item v-for="(banner, index) in midImages" :key="index" :src="banner"
              :cover="true"></v-carousel-item>
          </v-carousel>
          <v-carousel v-if="this.$vuetify.display.smAndDown" hide-delimiters :show-arrows="false"
            hide-delimiter-background>
            <v-carousel-item v-for="(banner, index) in mobileMidImages" :key="index" :src="banner"
              :cover="true"></v-carousel-item>
          </v-carousel>

          <v-card class="mx-auto card-margin">
            <template v-slot:title>
              <span class="font-weight-black">Topics We Explore</span>
            </template>

            <v-card-text class="bg-surface-light pt-4">
              <p>Stories of Resilience, Homemaker, Leadership & Success, Health & Wellness, Entrepreneurship &
                Innovation,
                Personal Growth & Development</p>
            </v-card-text>
          </v-card>

          <v-card class="mx-auto card-margin">
            <template v-slot:title>
              <span class="font-weight-black">Podcasts</span>
            </template>

            <v-card-text class="bg-surface-light pt-4">
              <v-container class="bg-surface-variant">
                <v-row no-gutters>
                  <!-- Assuming you have a list of podcast videos -->
                  <v-col v-for="video in youtubeVideos" :key="video.id" cols="12" sm="4">
                    <v-sheet class="ma-2 pa-2" outlined @click="playVideo(video.videoId)">
                      <img :src="video.thumbnail" v-if="activeVideoId !== video.videoId" class="video-thumbnail" />
                      <!-- <div class="play-button-overlay" v-if="activeVideoId !== video.videoId">
                        <div class="play-button"></div>
                      </div> -->
                      <iframe v-if="activeVideoId === video.videoId"
                        :src="`https://www.youtube-nocookie.com/embed/${video.videoId}?autoplay=1`" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen class="responsive-iframe"></iframe>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-row>
                  <v-col v-for="video in youtubeVideos" :key="video.id" cols="12" md="10" offset-md="1">
                    <div class="video-text">
                      <p class="video-description paragraph">{{ video.descriptionBefore }}</p>
                      <v-responsive class="video-container" @click="playVideo(video.videoId)">
                        <img :src="`https://img.youtube.com/vi/${video.videoId}/${video.thumbnailNum}.jpg`"
                          v-if="activeVideoId !== video.videoId" class="video-thumbnail" />
                        <div class="play-button-overlay" v-if="activeVideoId !== video.videoId">
                          <div class="play-button"></div>
                        </div>
                        <iframe v-if="activeVideoId === video.videoId"
                          :src="`https://www.youtube-nocookie.com/embed/${video.videoId}?autoplay=1`" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen class="responsive-iframe"></iframe>
                      </v-responsive>
                      <p class="video-description paragraph">{{ video.descriptionAfter }}</p>
                    </div>
                  </v-col>
                </v-row> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-app>
</template>

<script>
export default {
  data() {
    return {
      activeVideoId: null,
      banners: [
        require('@/assets/banner-3.png'),
        // require('@/assets/banner-1.jpg'),
        // require('@/assets/banner-2.jpg'),
      ],
      mobileBanners: [
        require('@/assets/banner-mobile-3.png'),
        // require('@/assets/banner-mobile-1.jpg'),
        // require('@/assets/banner-mobile-2.jpg'),
      ],
      midImages: [
        // require('@/assets/banner-3.png'),
        // require('@/assets/banner-1.jpg'),
        require('@/assets/banner-2.jpg'),
      ],
      mobileMidImages: [
        // require('@/assets/banner-mobile-3.png'),
        // require('@/assets/banner-mobile-1.jpg'),
        require('@/assets/banner-mobile-2.jpg'),
      ],
      youtubeVideos: [
        { id: 1, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 2, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 3, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 4, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 5, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 6, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 7, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 8, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
        { id: 9, videoId: "", thumbnail: require('@/assets/thumbnail.png'), thumbnailNum: "0", descriptionBefore: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", descriptionAfter: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: for a smooth scrolling effect
      });
    },
    playVideo(videoId) {
      this.activeVideoId = videoId;
      this.activeVideoId = null;
    },
  },
};
</script>

<style>
.card-margin {
  margin: 30px 0;
}


.paragraph {
  margin-bottom: 20px;
  line-height: 1.6;
}

.video-container {
  /* height: 720px; */
  /* max-height: 125vh; */
  /* margin-bottom: 40px; */
  position: relative;
  cursor: pointer;
}

.video-thumbnail {
  width: 100%;
  height: auto;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.play-button {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.play-button::after {
  content: '';
  border-top: 15px solid transparent;
  border-left: 30px solid #f44336;
  border-bottom: 15px solid transparent;
}

@media (max-width: 600px) {
  .video-container {
    height: 240px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .video-container {
    height: 480px;
  }
}

@media (min-width: 1025px) {
  .video-container {
    height: 720px;
  }
}
</style>

  <template>
    <v-app>
      <v-container>
        <v-row class="contact-page">
          <!-- Form Section -->
          <v-col cols="12" md="6" class="form-section">
            <v-card>
              <v-card-title>Contact Us</v-card-title>
              <v-card-text>
                <v-form ref="contactForm" v-model="formValid">
                  <v-text-field v-model="formData.name" label="Name" required></v-text-field>
                  <v-text-field v-model="formData.contactNumber" label="Contact Number" required></v-text-field>
                  <v-text-field v-model="formData.email" label="Email" required></v-text-field>
                  <v-textarea v-model="formData.message" label="Message" required></v-textarea>
                  <v-btn :disabled="!formValid" color="primary" @click="sendMessage">Send Message</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Contact Details Section -->
          <v-col cols="12" md="6" class="details-section">
            <v-card>
              <v-card-title>Reach Out to Us</v-card-title>
              <v-card-text>
                <div class="icon-container">
                  <v-icon large class="icon whatsapp-icon">mdi-whatsapp</v-icon>
                  <a :href="'https://wa.me/919820496752'" target="_blank" class="contact-link">+91 98204 96752</a>
                </div>
                <div class="icon-container">
                  <v-icon large class="icon phone-icon">mdi-phone</v-icon>
                  <span>+91 98204 96752</span>
                </div>
                <div class="icon-container">
                  <v-icon large class="icon email-icon">mdi-email</v-icon>
                  <span>uccamumbai@gmail.com</span>
                </div>
                <div class="icon-container">
                  <v-icon large class="icon location-icon">mdi-map-marker</v-icon>
                  <span>Mumbai, India</span>
                </div>
                <div class="social-icons">
                  <a href="https://www.facebook.com/profile.php?id=61566760003900" target="_blank"
                    rel="noopener noreferrer">
                    <v-icon large class="icon facebook-icon">mdi-facebook</v-icon>
                  </a>
                  <a href="https://www.instagram.com/streebal" target="_blank" rel="noopener noreferrer">
                    <v-icon large class="icon instagram-icon">mdi-instagram</v-icon>
                  </a>
                  <a href="https://www.youtube.com/@streebal" target="_blank" rel="noopener noreferrer">
                    <v-icon large class="icon youtube-icon" style="color: #FF0000;">mdi-youtube</v-icon>
                  </a>
                  <a href="https://www.linkedin.com/company/streebal" target="_blank" rel="noopener noreferrer">
                    <v-icon large class="icon youtube-icon" style="color: #0077B5;">mdi-linkedin</v-icon>
                  </a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </template>

  <script>
  export default {
    data() {
      return {
        formData: { name: '', contactNumber: '', email: '', message: '' },
        formValid: false,
      };
    },
    methods: {
      sendMessage() {
        // Code to send email
        const { name, contactNumber, email, message } = this.formData;
        const mailtoLink = `mailto:uccamumbai@gmail.com?subject=Message from ${name}&body=Name: ${name}%0D%0AContact Number: ${contactNumber}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;

        window.location.href = mailtoLink;
      },
    },
  };
  </script>

  <style scoped>
  .contact-page {
    margin-top: 20px;
  }

  .form-section {
    background-color: #f5f5f5;
    padding: 20px;
  }

  .details-section {
    background-color: #333333;
    color: #ffffff;
    padding: 20px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .icon {
    font-size: 64px;
    margin-right: 20px;
    color: inherit;
  }

  .whatsapp-icon {
    color: #25D366;
  }

  .phone-icon {
    color: #4CAF50;
  }

  .email-icon {
    color: #FF9800;
  }

  .location-icon {
    color: #F44336;
  }

  .social-icons {
    margin-top: 30px;
  }

  .social-icons .facebook-icon {
    color: #3b5998;
  }

  .social-icons .instagram-icon {
    color: #E1306C;
  }

  .social-icons .linkedin-icon {
    color: #0077B5;
  }

  .contact-link {
    color: #25D366;
    text-decoration: none;
  }

  .contact-link:hover {
    color: #128C7E;
    /* Darker green for hover */
  }
  </style>

<template>
  <v-app :class="{ 'v-theme--dark': isDarkTheme }">
    <v-toolbar app fixed>
      <v-toolbar-title>
        <div class="title-container">
          <v-img src="@/assets/logo.png" alt="Logo" max-width="40" max-height="40" class="mr-2"></v-img>
          <span>Stree Bal</span>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Button to toggle the drawer -->
      <v-btn icon @click="drawer = !drawer" v-if="this.$vuetify.display.smAndDown">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <!-- Desktop navigation buttons -->
      <v-btn text v-for="item in menuItems" :key="item.text" v-else @click="$router.push(item.route)">
        {{ item.text }}
      </v-btn>
      <!-- <v-btn text @click="$router.push('/')">Home</v-btn>
      <v-btn text @click="$router.push('/producer-host')">Producer & Host</v-btn>
      <v-btn text @click="$router.push('/team')">Team</v-btn>
      <v-btn text @click="$router.push('/contact-us')">Contact Us</v-btn> -->
      <v-spacer v-if="!this.$vuetify.display.smAndDown"></v-spacer>
      <v-switch v-model="isDarkTheme" label="Dark Theme" inset v-if="!this.$vuetify.display.smAndDown" />
    </v-toolbar>
    <!-- Navigation Drawer for smaller screens -->
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list>
        <v-list-item v-for="item in menuItems" :key="item.text" @click="$router.push(item.route)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <router-view></router-view>
    </v-container>
    <v-footer app>
      <v-container>
        <v-row>
          <v-col>
            <span>© 2024 Stree Bal</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      isDarkTheme: true,
      drawer: false, // for the navigation drawer
      menuItems: [
        { text: 'Home', route: '/' },
        { text: 'Team', route: '/team' },
        { text: 'Contact Us', route: '/contact-us' },
      ],
    };
  },
  watch: {
    isDarkTheme(val) {
      this.$vuetify.theme.global.name = val ? 'dark' : 'light';
    }
  }
};
</script>

<style scoped>
/* Ensure that the v-toolbar stays at the top and doesn't scroll */
.v-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Ensure it stays above other content */
}

.v-footer {
  padding-top: 0;
  padding-bottom: 0;
}

.v-footer .v-col {
  padding-top: 0;
  padding-bottom: 0;
}

.title-container {
  display: flex;
  align-items: center;
}

.v-input__details {
  display: none;
}
</style>
